// Fonts
@import url(http://fonts.googleapis.com/css?family=Lato:300,400,700,900);

// Vendors
// Should not use the extention file .css to contract the content to main out put file
@import "../css/vendor/animate.min";
@import "../css/vendor/font-awesome.min";
@import "../css/vendor/foundation";
@import "../css/vendor/morphext";
@import "../css/vendor/owl.carousel";
@import "../css/vendor/owl.theme";
@import "../css/vendor/owl.transitions";
@import "../css/vendor/slicknav";
@import "../css/vendor/tablesaw.stackonly";
@import "../css/vendor/style";
